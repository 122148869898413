.home-page header {
    background-image: url(../../../src/assets/images/background.webp);
}

header {
    background-size: cover;
    background-position: center;
}

header h1 {
    font-size: 5rem;
}

.home-page header h1,
.home-page header h2 {
    text-shadow: 2px 2px 8px #ffffff;
}

.menu-section {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(../../../src/assets/images/menu-img.jpg);
    background-size: cover;
    background-position: center;
}

.image-container {
    position: relative;
    display: inline-block;
}

.image-container img {
    border-style: solid;
    border-width: 1px;
    border-color: grey;
    transition: all 0.3s ease;
}

.image-container img:hover {
    border-color: darkgrey; 
    transform: scale(1.05);
}

.image-container::after {
    content: "View details";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    /* padding: 10px; */
    border-radius: 5px;
    opacity: 0;
    transition: opacity 0.3s ease;
    text-align: center;
    pointer-events: none; 
}

.image-container:hover::after {
    opacity: 1; 
}



@media only screen and (max-width: 768px) {
    header h1 {
        font-size: 4rem;
    }
}

@media only screen and (max-width: 576px) {
    header h1 {
        font-size: 3rem;
    }
}