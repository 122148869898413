/* fadeIn.css */
.school-details .fade-in {
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Custom Styles */
.school-details {
  background-color: #f0f0f0; /* Light gray background */
  padding: 50px 0;
}

.school-details .container {
  max-width: 900px;
  margin: 0 auto;
  background: transparent; /* Transparent to show the light background color */
  border-radius: 15px;
  padding: 30px;
}

.school-details .text-center {
  color: #2c3e50;
  font-family: 'Montserrat', sans-serif;
}

.school-details .text-center strong {
  color: #2c3e50; /* Dark gray for heading */
  font-size: 30px;
}

.school-details .text-start a {
  color: #2c3e50;
  text-decoration: none;
  font-weight: 500;
}

.school-details .text-start a:hover {
  color: #34495e; /* Darker gray on hover */
}

.school-details .text-start p {
  font-weight: bold;
  margin-right: 5px;
  color: #2c3e50;
}

.school-details hr {
  border: 1px solid #cccccc; /* Light gray for horizontal rule */
  width: 80%;
  margin: 20px auto;
}

.school-details .card {
  border: none;
  border-radius: 10px;
  background: #ffffff; /* White background for cards */
  color: #2c3e50;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow */
  padding: 20px;
  margin-bottom: 20px;
}

.school-details .card-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  letter-spacing: 1px;
  margin-bottom: 15px;
  text-decoration: underline;
}

.school-details .card-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
}

.school-details .card ul {
  padding-left: 0;
  list-style-type: none;
}

.school-details .card .fa-check {
  margin-left: 10px;
}

.school-details .icon {
  font-size: 20px;
  margin-right: 10px;
  color: #2c3e50; /* Dark gray icons */
}

@media (max-width: 768px) {
  .school-details .text-center {
    margin-top: 50px;
  }
}

/* Media query for mobile screens */
@media (max-width: 768px) {
  .image-section {
    order: 1;
    margin-bottom: 20px;
  }

  .text-section {
    order: 2;
  }

  .school-image {
    width: 100%;
    height: auto;
  }

  /* Ensure the content is fully centered */
  .school-details {
    padding: 15px;
  }

  /* Adjust margins and padding for better spacing */
  .text-section div, .image-section {
    margin-bottom: 10px;
  }
}


/* @media (max-width: 768px) {
  .d-flex {
    flex-direction: column !important;
    align-items: center;
  }
} */
/* @media (min-width: 769px) {
  .d-flex {
    flex-direction: row !important;
  }
} */
