.social-media-icons {
    display: flex;
    position: relative; 
    justify-content: center;
    padding: 0;
    margin: 0;
  }
  
  .social-media-icons li {
    list-style: none;
  }
  
  .social-media-icons li a {
    width: 60px; 
    height: 60px;
    background-color: #fff;
    text-align: center;
    line-height: 60px; 
    font-size: 25px;
    margin: 0 4px;
    display: block;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    border: 2px solid #fff; 
    z-index: 1;
    transition: background 0.5s;
  }
  
  .social-media-icons li a .icon {
    position: relative;
    color: #262626;
    transition: color 0.5s, transform 0.5s;
    z-index: 3;
  }
  
  .social-media-icons li a:hover .icon {
    color: #fff;
    transform: rotateY(360deg);
  }
  
  .social-media-icons li a:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f00;
    transition: top 0.5s;
  }
  
  .social-media-icons li a:hover:before {
    top: 0;
  }
  
  .social-media-icons li a.facebook:before {
    background: #3b5999;
  }
  
  .social-media-icons li a.twitter:before {
    background: #55acee;
  }
  
  .social-media-icons li a.linkedin:before {
    background: #0077b5;
  }
 
  .social-media-icons li a.instagram:before {
    background: '#feda75';
  }

  
  